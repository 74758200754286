
  import { defineComponent, nextTick, onMounted } from 'vue'
  import { initializeComponents } from './core/plugins/keenthemes'

  export default defineComponent({
    name: 'App',
    setup () {
      onMounted(() => {
        nextTick(() => {
          initializeComponents()
        })
      })
    }
  })
